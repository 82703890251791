import React from 'react'
import bannerImage from '../../assets/banner-weveMoved.png';

function Banners() {
  return (
    <div className="homeBanner">
      <img src={bannerImage} alt="Thank you Lynn. Come see us in Middleton" className="" />
    </div>
  )
}

export default Banners