export const navLinks = [
    {
      id: "/",
      title: "Home",
    },
    {id: "/productsandservices",
    title: "Products & Services"
    },
    // {
    //   id: "/products",
    //   title: "Products",
    // },
    // {
    //   id: "/services",
    //   title: "Services",
    // },
    {
      id: "/contactus",
      title: "Contact Us",
    },
  ];